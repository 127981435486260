import React, { useState, useEffect } from "react";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { Box, Button, Typography } from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/he"; // Import Hebrew locale
import "./Calendar.css?v=100";

const CustomCalendar = ({ value, onChange, onMonthChange, ...props }) => {
  // Use value as the selectedDate. This turns it into a controlled component.
  const [selectedDate, setSelectedDate] = useState(value || dayjs());

  // Whenever the parent passes a new value, update internal state.
  useEffect(() => {
    if (value && !selectedDate.isSame(value, "day")) {
      setSelectedDate(value);
    }
  }, [value, selectedDate]);

  const handlePrevMonth = () => {
    const newDate = selectedDate.subtract(1, "month");
    setSelectedDate(newDate);
    onMonthChange && onMonthChange(newDate);
  };

  const handleNextMonth = () => {
    const newDate = selectedDate.add(1, "month");
    setSelectedDate(newDate);
    onMonthChange && onMonthChange(newDate);
  };

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
    onChange && onChange(newDate);
  };

  const isCurrentMonth = selectedDate.isSame(dayjs(), "month");

  return (
    <Box className="custom-calendar">
      <Box display="flex" alignItems="center" justifyContent="center" mb={2} mt={2}>
        <Button className="nav-button prev" disabled={isCurrentMonth} onClick={handlePrevMonth}>
          <span className="triangle-right"></span>
        </Button>
        <Typography variant="h6" style={{ margin: "0 16px" }}>
          {selectedDate.locale("he").format("MMMM YYYY")}
        </Typography>
        <Button className="nav-button next" onClick={handleNextMonth}>
          <span className="triangle-left"></span>
        </Button>
      </Box>

      <DateCalendar
        value={selectedDate}
        onChange={handleDateChange}
        showDaysOutsideCurrentMonth={true}
      
        {...props}
      />
    </Box>
  );
};

export default CustomCalendar;
