import React from "react";
import { useDispatch, useSelector, useEf }
from "react-redux";
import "./Header.css";
import { logout }
from "../../../redux/actions/authActions";
import mayanot_logo from "../../../assets/images/mayanot_logo.jpg"
import yamim_logo from "../../../assets/images/yamim_logo.png"
import elad_logo from "../../../assets/images/elad_logo.png"
import ata_logo from "../../../assets/images/ata_logo.png"
import ans_logo from "../../../assets/images/logo.png"
import whatsApp from "../../../assets/images/whatsApp.svg";
import facebook from "../../../assets/images/facebook.svg";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Link, useNavigate }
from 'react-router-dom'
import { getHomeData }
from "../../../redux/actions/dataActions";
import { useEffect }
from "react";

const maxMobileWidth = 767;

const Header = (props) => {
const navigate = useNavigate()

        const dispatch = useDispatch();
        const { isAuthenticated, FirstName, LastName, user } = useSelector(
        (state) => state.auth
        );
        const { orders, data } = useSelector((state) => state.data);
        const logoutFunc = () => {
dispatch(logout());
        window.location.replace("/");
}

const isMobile = window.innerWidth <= 760

        let hostname = window.location.hostname;
        if (hostname.startsWith("www.")) {
         hostname = hostname.substring(4); // Remove "www."
         }

const logoMap = {
        "mayanot-hasharon.anstc.com": { logo: mayanot_logo, logo_height: "50px",logo_margin_top:"0px" },
        "yamim.anstc.com": { logo: yamim_logo, logo_height: "360px",logo_margin_top:"35px" },
         "elad.anstc.com": { logo: elad_logo, logo_height: "90px",logo_margin_top:"30px" },
          "mayanotata.anstc.com" : { logo: ata_logo, logo_height: "90px",logo_margin_top:"30px" },
     
        };
        
        const { logo, logo_height,logo_margin_top} = logoMap[hostname] || { logo: ans_logo, logo_height: "60px",logo_margin_top:"30px"};
        
        return (
                <section className="header is-sticky">
    <img src={logo}    style={{ height: logo_height,marginTop: logo_margin_top }}   alt="logo" className="logo" onClick={() => navigate('/')} />
    {isAuthenticated ? (
                <></>
                ) : null}
    <div className="side-header">
        {/*<div className="side-header-items">
         {isAuthenticated && <span className="logout" onClick={logoutFunc}>התנתקות</span>}
         </div>*/}
    </div>

</section>
                );
        };
export default Header;
