import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/he';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { API_URL } from '../../../redux/actions/api';
import { updateAppointment } from '../../../redux/actions/dataActions';
import { getMeDetails } from '../../../redux/actions/authActions'; // adjust the path if needed
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import './Calendar.css';
import CustomCalendar from './CustomCalendar';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

dayjs.locale('he');

function Calendar(props) {
  const dispatch = useDispatch();
  const [highlightedDays, setHighlightedDays] = useState([]);
  const { loading } = useSelector((state) => state.async);
  const navigate = useNavigate();

  const [currentDate, setCurrentDate] = useState(dayjs());
  const [times, setTimes] = useState([]);
  const [slots, setSlots] = useState([]);
  const [currentSlot, setCurrentSlot] = useState(null);

  // State for error handling with Snackbar
  const [errorMessage, setErrorMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // Load slots from the API and set initial date, highlighted days, and times.
  useEffect(() => {
    axios
      .get(API_URL + 'doctors/?schedule_id=' + props.visitor.schedule_id)
      .then((res) => {
        const slt = res?.data?.data?.rows[0]?.slots.sort((a, b) => {
          const dateA = dayjs(a.wdate);
          const dateB = dayjs(b.wdate);
          const timeA = dayjs(a.start_time, 'HH:mm:ss');
          const timeB = dayjs(b.start_time, 'HH:mm:ss');
          if (!dateA.isSame(dateB)) {
            return dateA - dateB;
          }
          return timeA - timeB;
        });
        setSlots(slt);

        let initialValue;
        if (props.visitor.appointed_time) {
          // For editing, use the visitor’s appointed time.
          initialValue = dayjs(props.visitor.appointed_time);
        } else {
          // For new appointments, use the first available slot (if any)
          const firstAvailableSlot = slt.find(slot => slot.is_available);
          initialValue = firstAvailableSlot ? dayjs(firstAvailableSlot.wdate) : dayjs();
        }
        setCurrentDate(initialValue);

        // For highlighting, in edit mode highlight all working days in the month.
        const workingDays = slt
          .filter(s => dayjs(s.wdate).isSame(initialValue, 'month'))
          .map(s => Number(dayjs(s.wdate).format('DD')));
        setHighlightedDays([...new Set(workingDays)]);

        // Immediately compute timeslots for the current date.
        const computedTimes = slt.filter(s =>
          dayjs(s.wdate).isSame(initialValue, 'day')
        );
        setTimes(computedTimes);

        // (Optional) Debug logs:
        console.log("Initial currentDate:", initialValue.format('YYYY-MM-DD'));
        console.log("Initial times computed:", computedTimes);
      })
      .catch((err) => {
        console.error("Error fetching doctor slots", err);
      });
  }, [props.visitor.schedule_id, props.visitor.appointed_time]);

  // Whenever currentDate or slots change, recalc times (to be safe).
  useEffect(() => {
    if (slots.length > 0 && currentDate) {
      const newTimes = slots.filter(s =>
        dayjs(s.wdate).isSame(currentDate, 'day')
      );
      setTimes(newTimes);
    }
  }, [currentDate, slots]);

  // The custom day renderer.
  const ServerDay = (props) => {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;
    const isSelected = !outsideCurrentMonth && highlightedDays.indexOf(day.date()) >= 0;
    return (
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        disabled={!isSelected}
        day={day}
      />
    );
  };

  // This function filters times based on the given date.
  const Change = (value) => {
    const newTimes = slots.filter(s =>
      dayjs(s.wdate).isSame(value, 'day')
    );
    setTimes(newTimes);
  };

  const ChooseTime = (time) => {
    setCurrentSlot(time);
    setErrorMessage('');
  };

  // This function is called when the user changes the month in the calendar.
  const handleMonthChange = (date) => {
    setTimes([]);

    // Filter all slots for the new month.
    const monthSlots = slots.filter(s => dayjs(s.wdate).isSame(date, 'month'));

    // Build highlighted days from all working slots (regardless of availability) in that month.
    let daysInMonth = monthSlots.map(s => Number(dayjs(s.wdate).format('DD')));

    // If editing and the appointment is in this month, ensure its day is included.
    if (props.visitor.appointed_time && dayjs(props.visitor.appointed_time).isSame(date, 'month')) {
      const appointedDay = dayjs(props.visitor.appointed_time).date();
      if (!daysInMonth.includes(appointedDay)) {
        daysInMonth.push(appointedDay);
      }
    }
    setHighlightedDays([...new Set(daysInMonth)]);

    // Determine the new selected date for the month.
    if (monthSlots.length > 0) {
      let newDate;
      if (props.visitor.appointed_time && dayjs(props.visitor.appointed_time).isSame(date, 'month')) {
        // If editing and the appointment is in this month, use that date.
        newDate = dayjs(props.visitor.appointed_time);
      } else {
        // For a new appointment, choose the first available slot if possible.
        const availableSlots = monthSlots.filter(s => s.is_available);
        if (availableSlots.length > 0) {
          const firstAvailableDay = Math.min(
            ...availableSlots.map(s => dayjs(s.wdate).date())
          );
          newDate = dayjs(date).date(firstAvailableDay);
        } else {
          // Fallback: use the earliest working day.
          const firstWorkingDay = Math.min(
            ...monthSlots.map(s => dayjs(s.wdate).date())
          );
          newDate = dayjs(date).date(firstWorkingDay);
        }
      }
      setCurrentDate(newDate);
      const newTimes = slots.filter(s => dayjs(s.wdate).isSame(newDate, 'day'));
      setTimes(newTimes);
    } else {
      setCurrentDate(date);
      setTimes([]);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const UpdateFunc = async () => {
    if (!currentSlot) {
      return;
    }
    try {
      await dispatch(
        updateAppointment({
          id: props.visitor.id,
          formData: {
            slot_data_id: currentSlot.id,
            appointed_time: currentSlot.start_time,
            timeData: { wdate: currentSlot.wdate },
          },
        })
      ).unwrap();

      await dispatch(getMeDetails());
      props.close();
      navigate('/');
    } catch (error) {
      const errMessage =
        typeof error === 'string'
          ? error
          : error?.message || 'An unexpected error occurred';
      setErrorMessage(errMessage);
      setSnackbarOpen(true);
    }
  };

  return (
    <>
      <div className='headDial'>
        <h3>עריכת תור</h3>
        <div className='drName'>{props?.visitor?.schedule.title}</div>
      </div>

      <LocalizationProvider dateAdapter={AdapterDayjs} locale='he'>
        <CustomCalendar
          value={currentDate}
          onChange={(value) => {
            setCurrentDate(value);
            Change(value);
          }}
          onMonthChange={handleMonthChange}
          views={['day']}
          slots={{ day: ServerDay }}
          slotProps={{ day: { highlightedDays } }}
        />
      </LocalizationProvider>

      <div className='time-warp edit_tor'>
        {times.map((time) => (
          <span
            key={time.id}
            className={'time-slot ' + (currentSlot?.id === time.id ? 'active' : '')}
            onClick={() => ChooseTime(time)}
          >
            {dayjs('2023-12-12 ' + time.start_time).format('HH:mm')}
          </span>
        ))}
      </div>

      <LoadingButton
        disabled={!currentSlot}
        loading={loading}
        variant='contained'
        className='close-btn edit_tor'
        onClick={UpdateFunc}
        sx={{ borderRadius: 0 }}
        endIcon={<CheckIcon />}
      >
        אישור
      </LoadingButton>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{ bottom: '220px !important' }}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
    </>
  );
}

export default Calendar;
