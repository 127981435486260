import axios from "axios";
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError,
} from "./asyncActions";
import {  REMOVE_VISITOR, LOGOUT, START_FORM, USER_DATA, ADD_VISITOR, FAILED,UPDATE_VISITOR } from "./types";
import { API_URL } from "./api";
import { open } from "./modalActions";
import { createAsyncThunk } from '@reduxjs/toolkit';


export const getHomeData = (docNum) => async (dispatch) => {
  dispatch(asyncActionStart());
  try {
    const url = `${API_URL}user/me`;

    const res = await axios.get(url, { docNum: docNum });

    if (res.error) {
     
      dispatch(asyncActionError(res.error));
    } else {
      dispatch({
        type: USER_DATA,
        payload: res.data.data,
      });

      dispatch(asyncActionFinish());
    }
  } catch (error) {
    console.log(error);
    if (error?.response.status == 401) {
      dispatch({
        type: LOGOUT,
      });
      dispatch(asyncActionError("אנא היכנס מחדש"));
    } else if (
      error.response &&
      error.response.data &&
      error.response.data.message
    ) {
      dispatch(asyncActionError(error.response.data.message));
    } else {
      dispatch(asyncActionError("Server error"));
    }
  }
};



export const sendAppointment = (formData) => async dispatch => {
  dispatch(asyncActionStart())
  try {   
    const res = await axios.post(`${API_URL}visitors`, formData)
    if(res.data.data.error) {
        console.log("sendAppointment err",res.data)
         dispatch({
          type: FAILED,
          payload: res.data.data
        });
        dispatch(asyncActionError(res.data.data.error))
    } else {
       
      console.log("sendAppointment good",res.data)
        dispatch({
          type: ADD_VISITOR,
          payload: res.data.data
        })
        dispatch(open('snack_bar'))
        dispatch(asyncActionFinish())
    }
   
  } catch (error) {    
      
         console.log("sendAppointment CATCH ERR","ERR")
    if (error.response && error.response.data && error.response.data.title) {
      // dispatch(asyncActionError(error.response.data.title))
      dispatch(asyncActionError(error.response.data))
    } else {     
        dispatch(asyncActionError('Server error'))    
    }
  }
}



export const deleteAppointment = (id) => async dispatch => {
  dispatch(asyncActionStart())
  try {   
    const res = await axios.delete(`${API_URL}visitors/${id}`)
   
    if(res.status == 204) {
     
        dispatch({
          type: REMOVE_VISITOR,
          payload: id
        })
        dispatch(open('snack_bar'))
        dispatch(asyncActionFinish())
    }
   
  } catch (error) {    
    if (error.response && error.response.data && error.response.data.title) {
      // dispatch(asyncActionError(error.response.data.title))
      dispatch(asyncActionError(error.response.data))
    } else {     
        dispatch(asyncActionError('Server error'))    
    }
  }
};


export const updateAppointment = createAsyncThunk(
  'appointments/update',
  async ({ id, formData }, { rejectWithValue }) => {
      

    try {
      const res = await axios.put(`${API_URL}visitors/${id}`, formData);
      
      // Check if the response contains an error message
      if (res.data.data && res.data.data.error) {
        return rejectWithValue(res.data.data.error);
      }
      
      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.title) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue('Server error');
    }
  }
);



export const startForm = (row) => {
  return {
    type: START_FORM,
  };
};





