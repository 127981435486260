
const apiMapping = {
  "yamim.anstc.com": "https://yamim.anstc.com/backend/v1/",
  "ans.mipo.co.il": "https://appointments-ans.mipo.co.il/v1/",
  "mayanot-hasharon.anstc.com": "https://mayanot-hasharon-admin.anstc.com/v1/",
  "elad.anstc.com": "https://elad.anstc.com/backend/v1/",
  "mayanotata.anstc.com": "https://mayanotata.anstc.com/backend/v1/",
};

export const API_URL = apiMapping[window.location.hostname] || "https://elad.anstc.com/backend/v1/";

