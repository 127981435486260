import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Steps.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Button } from '@mui/material';
import dayjs from 'dayjs';
import moment from "moment";
import { API_URL } from "../../../redux/actions/api";

import axios from "axios";
import { sendAppointment } from "../../../redux/actions/dataActions";
import AddTaskRoundedIcon from '@mui/icons-material/AddTaskRounded';

function StepFailedAppoint(props) {
  const location = useLocation();
  const { user } = useSelector((state) => state.auth)
  const [formData, setFormData] = useState(props.formData);
  const [slots, setSlots] = useState([]);
  const [highlightedDays, setHighlightedDays] = useState([]);
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();
  const department = user?.departments.find(d => d.id == props.formData.department_id)
  const [doctors, setDoctors] = useState([]);
   const { failed_visitor_error } = useSelector((state) => state.data)
 
    useEffect(() => {
        // console.log('schedule_id',props.formData.schedule_id);
    if(!load) {
      axios.get(API_URL +'doctors?schedule_id=' + props.formData.schedule_id).then((res) =>{
   
         const newSlots = res?.data?.data?.rows[0]?.slots;
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    doctor: {
                         ...prevFormData.doctor || {},
                        slots: newSlots,
                       
                    }
                }));
           
 

      })
    }
  },[props.formData.schedule_id]);
  
  useEffect(() => {
  //console.log('Updated formData:', formData);
}, [formData]);
    
 
 
  
  
  const Next = () => {
      
      //props.next();
    navigate('/')
  }
   const NextForFailed = () => {
      
     props.setFormData({...props.formData, 
      schedule_id : props.formData.schedule_id, 
      doctor: formData.doctor     
    })
      props.next_for_failed();
   // navigate('/')
  }

  return (
    <div className="step step5">
      <h3>{props?.formData?.fname}</h3>
      <h5 className="validate_date_failed">{failed_visitor_error}</h5>
      {/* <AddTaskRoundedIcon /> */}
      
      <Card>
        <div className="dep_title">{department?.title}</div>
        <div>{props?.formData?.doctor?.title}</div>
        <br />
        <div><b>{props?.formData?.doctor?.address}</b></div>

        <br />
        <div className="flex_el">
          <div>
            תאריך <br />
            <b>{moment(props?.formData?.timeData?.wdate).format("DD/MM/YYYY")}</b>
          </div>
          <div>
            שעה <br />
            <b>{props?.formData?.timeData?.start_time.slice(0,-3)}</b>
          </div>
        </div>

      </Card>

      <Button variant="contained"
        className="next-btn" onClick={NextForFailed}>סיום</Button>

    </div>
  );
}

export default StepFailedAppoint;
