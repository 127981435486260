import {combineReducers} from 'redux'
import authReducer from './authReducer'
import asyncReducer from './asyncReducer'
import modalReducer from './modalReducer'
import dataReducer from './dataReducer'
import appointmentsReducer from '../appointmentsSlice';


const rootReducer = combineReducers({
  appointments: appointmentsReducer,   
  auth: authReducer,
  async: asyncReducer,
  modal: modalReducer,
  data: dataReducer

})

export default rootReducer
