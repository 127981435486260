import axios from 'axios';
import {asyncActionStart, asyncActionFinish, asyncActionError} from './asyncActions';
import setAuthToken from '../../utils/auth/setAuthToken';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  SET_AS_AUTHENTICATED,
  LOGOUT,
  USER_DATA,
  OTP,
  RESTART_OTP
} from './types'
import { API_URL } from './api'

// Login User
// export const sendOtp=(data)=> 
export const sendOtp = (formData) => async dispatch => {
  dispatch(asyncActionStart())
  try {
    
    const url = `${API_URL}user/send-otp`

    const res = await axios.post(url, formData)
    if (res.data.error_message) {
        dispatch(asyncActionError(res.data.error_message))
    }
    else {
        dispatch({
            type: OTP,
            payload: res.data.data
          })

          // In order to test, clean token from localStorage
          // loadUser(); // Gets user from the backend
         
          
          dispatch(asyncActionFinish())
    }
   
  } catch (error) {
    console.log(error)
    dispatch({
      type: RESTART_OTP,
      payload: { errorMsg: error.response.data.error_message },
    })
    if (error.response && error.response.data && error.response.data.error_message) {
      
      dispatch(asyncActionError(error.response.data.error_message))
    } else {
      dispatch(asyncActionError('Server error'))
    }
  }
  
}

export const login = (formData) => async dispatch => {
  dispatch(asyncActionStart())
  try {
    const url = `${API_URL}user/login`

    const res = await axios.post(url, formData)
    if(res.data.error) {
        dispatch(asyncActionError(res.data.error))
    }
    else {
      console.log(res.data.data.token)
        dispatch(setAsAuthenticated(res.data.data.token))
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data.data
          })
          dispatch(asyncActionFinish())
    }
   
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL,
      payload: { errorMsg: error.response.data.message } 
    })

    
    if (error.response && error.response.data && error.response.data.title) {
      // dispatch(asyncActionError(error.response.data.title))
      dispatch(asyncActionError(error.response.data))
    } else {
      dispatch(asyncActionError('Server error'))
    }
  }
  
}

export const setAsAuthenticated = authToken => {
  //localStorage.setItem("AuthToken", authToken);
  localStorage.setItem("token", authToken);
  setAuthToken(authToken)
  return {type: SET_AS_AUTHENTICATED, payload: authToken}
}


export const getMeDetails = () => async dispatch => {
  dispatch(asyncActionStart())
  try {   

    const res = await axios.get(`${API_URL}user/me/`)
    if(res.data.data.error) {
        dispatch(asyncActionError(res.data.data.error))
    }
    else {
        dispatch({
            type: USER_DATA,
            payload: res.data.data
          })
          
         
          dispatch(asyncActionFinish())
    }
   
  } catch (error) {    
    if (error.response && error.response.data && error.response.data.title) {
      // dispatch(asyncActionError(error.response.data.title))
      dispatch(asyncActionError(error.response.data))
    } else {
      if(error.response.status == 401) {
          dispatch({
            type: LOGOUT,
        })
        dispatch(asyncActionError('אנא היכנס מחדש'))

      } else {
        dispatch(asyncActionError('Server error'))
      }
    
    }
  }
}

export const fetchUserData = createAsyncThunk(
  'auth/fetchUserData',
  async (_, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${API_URL}user`); // Adjust the endpoint as necessary
      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      return rejectWithValue(error.response.data || 'Server error');
    }
  }
);
export const restartOtp=()=>{
  return{
    type: RESTART_OTP,
  }
}
// Logout
export const logout = () => {
  /*setAuthToken(false)
  return {
    type: LOGOUT
  }*/
}

