import { HOME,  ALL_DEPARTMENTS, ADD_VISITOR, FAILED,START_FORM } from "../actions/types";

const initialState = {
 success_visitor: false,
 failed_visitor : false
};

const dataReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case HOME:
      return {
        ...state,
        data: payload,
      }
    case ADD_VISITOR:
      return {
        ...state,
        success_visitor: true,
          failed_visitor: false
      }
      case START_FORM:
        return {
          ...state,
          success_visitor: false,
          failed_visitor: false
        }
        
      case FAILED:
        return {
              ...state,
          success_visitor: false,
          failed_visitor: true,
          failed_visitor_error: payload.error
        }
   
    default:
      return state;
  }
};

export default dataReducer;
