// src/redux/appointmentsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from './actions/api';


export const updateAppointment = createAsyncThunk(
  'appointments/update',
  async ({ id, formData }, { rejectWithValue }) => {
    try {
      const res = await axios.put(`${API_URL}visitors/${id}`, formData);
      if (res.data.data && res.data.data.error) {
        return rejectWithValue(res.data.data.error);
      }
      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.title) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue('Server error');
    }
  }
);

const appointmentsSlice = createSlice({
  name: 'appointments',
  initialState: {
    visitors: [],
    loading: false,
    error: null,
  },
  reducers: {
    // Define other synchronous actions if needed
  },
  extraReducers: (builder) => {
    builder.addCase(updateAppointment.fulfilled, (state, action) => {
      const index = state.visitors.findIndex(
        (visitor) => visitor.id === action.payload.id
      );
      if (index !== -1) {
        // Replace the old appointment with the updated one
        state.visitors[index] = action.payload;
      }
    });
    builder.addCase(updateAppointment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateAppointment.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default appointmentsSlice.reducer;
