import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendOtp, login, restartOtp } from "../../../redux/actions/authActions";
import ReCAPTCHA from "react-google-recaptcha";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { LoadingButton } from '@mui/lab';
import FormControlLabel from '@mui/material/FormControlLabel';

import "./Login.css";
import Checkbox from '@mui/material/Checkbox';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';

function isIos() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

 /*  if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
      return "Android";
  }
 */

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return true;
  }

  return false;
}

const Login = () => {
  const { otp, errorMsg } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [phone, setPhone] = useState(null);
  const [token, setToken] = useState(null);
  const [otpValue, setOtp] = useState(null);
  const [terms, setTerms] = useState(true);
  const { loading } = useSelector((state) => state.async);
  const recaptchaRef = React.useRef();

  const handleOtpRetrieval = () => {
    const ac = new AbortController()
    navigator.credentials.get({
        otp: { transport: ['sms'] },
        signal: ac.signal
    }).then(otp => {
        setOtp(otp)
        //alert(`got otp from client===>${otp}`)
    }).catch(err => {
       // alert(`err: ${err}`)
    })
}



  useEffect(() => {
          /**OTPConnection**/
          if ('OTPCredential' in window && isIos() ) {
              handleOtpRetrieval()
          }
  }, [])
  
  const handleSubmit = (e) => {
    e.preventDefault()
    if (!otp) {
      //  recaptchaRef.current.executeAsync().then(dtoken => {
      dispatch(sendOtp({ phone: phone, recaptcha: 'token' }));
      // recaptchaRef.current.reset()
      //  })
    } else {
      dispatch(login({ otp: otpValue, phone: phone, }));
    }
  };

  const CaptchaChange = (token) => {
    setToken(token);

  };
  const refreshOtp = () => {
    window.location.reload();
  };

let hostname = window.location.hostname;
        if (hostname.startsWith("www.")) {
         hostname = hostname.substring(4); // Remove "www."
         }
 const loginFormMap = {
        "mayanot-hasharon.anstc.com": {login_form_margin_top:"0px" },
        "yamim.anstc.com": {login_form_margin_top:"90px" },
        "elad.anstc.com": {login_form_margin_top:"60px" },
         "mayanotata.anstc.com":{login_form_margin_top:"80px" },
        };
        
        const { login_form_margin_top} = loginFormMap[hostname] || {login_form_margin_top:"0px" };

  if (otp === 'fresh') {
    // refreshOtp()
  }

  return (
    <div className="login_page" style={{marginTop:login_form_margin_top}}>
      <div className="r_side">
        <Box
          className={"box_login"}
          component="form"
          onSubmit={handleSubmit}
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <h2 className="login_title">מערכת לזימון תורים</h2>
          <div className="content_login_form" >
            {!otp || otp === 'fresh' ? (
              <>
                <TextField
                  className="input_tel"
                  style={{ width: '100%' }}
                  required
                    autoComplete="off"
                  label="מספר נייד"
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder={"אנא הזן מספר נייד"}
                  type="tel"
                />

              </>
            ) : ''}

            {otp && otp != 'fresh' ? (
              <div className="otp_form">
               <TextField
  style={{ width: '100%', direction: 'rtl' }}
  required
  onChange={(e) => setOtp(e.target.value)}
  label="קוד אימות"
  placeholder={"אנא הזן סיסמא שנשלחה ב - SMS"}
  type="tel"
  inputProps={{
    inputMode: 'numeric', // Suggest numeric keyboard
    pattern: '[0-9]*',    // Regex pattern for numeric input
    autoComplete: 'one-time-code' // Enable autocomplete for OTP
  }}
/>

                  {errorMsg && <div className="errorMsg"> {errorMsg} </div>}
                
                  <div className="restert" onClick={refreshOtp}>
                    לא קיבלתי קוד, שלח שוב
                  </div>
                </div>
             
            ) : ""}

          </div>
          {<LoadingButton disabled={!terms || !phone} loading={loading} onClick={handleSubmit} variant="contained" className="login-btn" fullWidth  sx={{ borderRadius: 0, fontSize: 18 }} >
            {!otp ? "שלח קוד" : "התחבר"}
          </LoadingButton>}
        </Box>
      </div>
    </div>
  );
};

export default Login;
