import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Steps.css";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from 'dayjs';
import axios from "axios";
import { API_URL } from "../../../redux/actions/api";
import Card from '@mui/material/Card';
import moment from "moment";
import { TextField } from "@mui/material";
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';

function Step3(props) {
    console.log("step 3 start");  
  const location = useLocation();
  const {user} = useSelector((state) => state.auth)
  const department = user?.departments.find(d => d.id == props.formData.department_id)

  const [load, setLoad] = useState(false)
  const [doctors, setDoctors] = useState([])

  const [inputVal, setInputVal] = useState('')

  useEffect(() => {
    if(!load) {
      axios.get(API_URL +'doctors?department_id=' + props.formData.department_id).then((res) =>{
        setDoctors(res.data.data.rows)
      })
    }
  },[])

  const Click = (doctor) => {
    if(!doctor.slots || !doctor.slots.length) {
      return;
    }

    props.setFormData({...props.formData, 
      schedule_id : doctor.id, 
      doctor: doctor     
    })
    props.next()
  }

  const SearchFunc = (e) => {
    setInputVal(e.target.value)
    axios.get(API_URL +'doctors?department_id=' + props.formData.department_id + '&q=' + e.target.value).then((res) =>{
      setDoctors(res.data.data.rows)
    })
  }

  const Slot = (props) => {
       const slots = props.slots.sort((a, b) => {
  const dateA = dayjs(a.wdate);
  const dateB = dayjs(b.wdate);
  const timeA = dayjs(a.start_time, 'HH:mm:ss');
  const timeB = dayjs(b.start_time, 'HH:mm:ss');

  // Compare dates first
  if (!dateA.isSame(dateB)) {
    return dateA - dateB;
  }

  // Dates are the same, compare times
  return timeA - timeB;
});  
   // const firstSlot = props.slots.sort((a, b) => moment(a.wdate).isBefore(moment(b.wdate)))[0];
     const firstSlot = slots[0];

    return (
      <div>
        התור הפנוי הקרוב ביותר
        <br/>
        {moment(firstSlot.wdate).format('DD/MM/YYYY')} &nbsp;|&nbsp;
        בשעה {moment('2023-12-12 ' + firstSlot.start_time).format('HH:mm')}
      </div>
    )

  }

  
  return (
    <div className="step step3">
      <h3>{props?.formData.fname}</h3>
      <h5>{department?.title}</h5>

{/*<TextField value={inputVal} onChange={SearchFunc} className="search_med" autoFocus fullWidth placeholder="חיפוש שם / כתובת" />*/}

      <hr/>
   {doctors.length > 0 && (
      <div className="intro-text">אנא בחר שירות מבוקש</div>
    )}
  <div className="doctors-list">
      {doctors.map(doctor => (
        console.log(doctor),
         <Card onClick={() => Click(doctor)} key={doctor.id}>
          <div className="doc_title bold">{doctor.title}</div>
          <div className="doc_address"> 
              <LocationOnRoundedIcon />
              {doctor.address}
          </div>
          <br/>
          {(!doctor.slots || !doctor.slots.length) 
            ? <div>כרגע אין לנו תור פנוי</div>
            : <Slot  slots={doctor.slots}/>}
          </Card>
      ))}
       </div>
        
    </div>
  );
}

export default Step3;
