import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Appointments.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Tab, Card } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';
import moment from 'moment';
import { startForm } from '../../redux/actions/dataActions';

function Appointments() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = useState('future');

  const { user } = useSelector((state) => state.auth);
  const visitors = user?.visitors || [];


  const pastVisitors = visitors.filter((v) =>
    moment(v.appointed_time).isBefore(moment())
  );
  const futureVisitors = visitors.filter((v) =>
    moment(v.appointed_time).isAfter(moment())
  );

let hostname = window.location.hostname;
        if (hostname.startsWith("www.")) {
         hostname = hostname.substring(4); // Remove "www."
         }
 const homeWrapMap = {
        "mayanot-hasharon.anstc.com": {home_wrap_margin_top:"0px" },
        "yamim.anstc.com": {home_wrap_margin_top:"90px" },
         "elad.anstc.com": {home_wrap_margin_top:"60px" },
         "mayanotata.anstc.com":{home_wrap_margin_top:"80px" },
        };
        
        const { home_wrap_margin_top} = homeWrapMap[hostname] || {home_wrap_margin_top:"0px" };
        
  const AppointmentCard = ({ data, type }) => {
    const department = user?.departments.find(
      (d) => d.id === data.department_id
    );
 
 
   
    return (
      <div>
        <Card
          className="appointment"
          onClick={() => navigate('/appointment/' + data.id)}
        >
          <div className="bold">
            {moment(data.appointed_time).format('DD/MM/YYYY')} בשעה{' '}
            {moment(data.appointed_time).format('HH:mm')}
            <br />
            {department.title}, {data.schedule?.title}
          </div>
          <br />
          <div>
            תור עבור {data.fname} {data.lname} <br />
            {data.schedule?.address}
          </div>
        </Card>
        <hr className="appointment_hr" />
      </div>
    );
  };

  return (
    <div style={{marginTop : home_wrap_margin_top}} className="homeWrap">
      <div className="title_head">
        <h1>התורים שלי</h1>
        <Button
          variant="contained"
          onClick={() => {
            dispatch(startForm());
            navigate('/form');
          }}
          endIcon={<AddIcon />}
          sx={{ borderRadius: 20 }}
        >
          לקביעת תור חדש
        </Button>
      </div>

      <TabContext value={currentTab}>
        <TabList
          className="tab-list"
          variant="fullWidth"
          onChange={(e, val) => setCurrentTab(val)}
        >
          <Tab label="תורים קרובים" value="future" className="selectedTabIndicator" />
          <Tab label="תורים קודמים" value="past" />
        </TabList>

        <TabPanel value="future">
          {futureVisitors.map((v) => (
            <AppointmentCard data={v} key={v.id} type="future" />
          ))}
        </TabPanel>
        <TabPanel value="past">
          {pastVisitors.map((v) => (
            <AppointmentCard data={v} key={v.id} type="past" />
          ))}
        </TabPanel>
      </TabContext>
    </div>
  );
}

export default Appointments;
