import { useDispatch, useSelector } from "react-redux";
import "./Appointments.css";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import moment from 'moment';
import { Button, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from "@mui/material/Typography";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useEffect, useState } from "react";
import { CloseOutlined } from "@mui/icons-material";
import { deleteAppointment} from "../../redux/actions/dataActions";
import { updateAppointment } from '../../redux/appointmentsSlice';
import Calendar from "./calendar/Calendar";
import { useMediaQuery, useTheme } from '@mui/material';

function Appointment() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

 
  const [delOpen, setDelOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const { user } = useSelector((state) => state.auth);
   const note = "יש להגיע 10 דקות לפני מועד הפגישה ולהצטייד במסמכים הנדרשים";


  const visitor = user?.visitors?.find(v => v.id == location.pathname.replace('/appointment/', ''));
  const { id } = useParams(); 
  const appointment = user?.visitors?.find((v) => v.id === parseInt(id));

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const DeleteFunc = () => {
    dispatch(deleteAppointment(visitor.id));
  };

  useEffect(() => {
      
    if (!visitor) {
      navigate('/');
    }
   
  }, [visitor]);

  useEffect(() => {
    if (!appointment) {
      navigate('/');
    }
  }, [appointment, navigate]);

  if (!appointment) {
    return null; // Or a loading indicator while navigating
  }

  const isFuture = moment(appointment.appointed_time).isAfter(moment());
  const type = isFuture ? 'future' : 'past';

  return (
    <div className="">
      <IconButton aria-label="back" onClick={() => navigate('/')} className="back_btn" size="small">
        <ArrowForwardIosIcon />
      </IconButton>
      <div className="note_warp">
        <h3>הנחיות לפני ביקור</h3>
        <div className='notes' dangerouslySetInnerHTML={{ __html: visitor.mynote||note }}></div>
      </div>
      {type === 'future' ? (
        <div className="control-inputs">
          <a className="del" onClick={() => setDelOpen(true)}>לביטול התור</a>
          <a className="edit" onClick={() => setEditOpen(true)}>לעריכת תור</a>
        </div>
      ) : (
        <div></div>
      )}
      <Dialog
        open={delOpen}
        onClose={() => setDelOpen(false)}
        className="del_dialog"
        PaperProps={{
          className: 'custom-dialog',
          style: {
            maxWidth: '450px', // Ensure the dialog does not exceed 450px
            width: '100%', // Make sure the dialog takes full width up to 450px
          },
        }}
      >
        <IconButton
          aria-label="close"
          onClick={() => setDelOpen(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseOutlined />
        </IconButton>
        <DialogContent id="alert-del-dialog">
          <DialogContentText>
            <br /><br />
            <b className="delTor">? האם לבטל את התור שלך</b>
            <br /><br />
          </DialogContentText>
          <div className="button-container">
            <Button variant="contained" sx={{ borderRadius: 0 }} className="del-btn" onClick={DeleteFunc}>
              אישור
            </Button>
            <Button 
              variant="contained" 
              className="close-btn" 
              onClick={() => setDelOpen(false)} 
              sx={{ borderRadius: 0 }}
            >
              חזרה
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={editOpen}
        onClose={() => setEditOpen(false)}
        className="edit_dialog"
        PaperProps={{
          className: 'phone_container_in',
          style: {
            maxWidth: fullScreen ? '100%' : '360px', // 100% for mobile, 360px for desktop
            width: '100%', // Make sure the dialog takes full width up to the maxWidth
            height: '100%',
            margin: 0,
            maxHeight: '100%',
            borderRadius:fullScreen ?'0px':'25px',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            direction: 'rtl',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <div
          style={{
            position: 'relative',
            width: '100%',
            direction :'rtl'
          }}
        >
          <IconButton
            className="closeIcon"
            aria-label="close"
            onClick={() => setEditOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseOutlined />
          </IconButton>
        </div>
        <DialogContent
          className="alert_edit_dial"
          id="alert-del-dialog"
          sx={{
            padding: 0,
            overflowY: 'auto',
            flexGrow: 1,
          }}
        >
          <Calendar visitor={visitor} close={() => setEditOpen(false)} />
        </DialogContent>
      </Dialog>

      <Button variant="contained" className="next-btn" onClick={() => navigate('/')}>הבנתי תודה</Button>
    </div>
  );
}

export default Appointment;
