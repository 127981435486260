import {  useRef, useState } from "react";
import {  useSelector } from "react-redux";
import "./Steps.css";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import CustomCalendar from '../../appointments/calendar/CustomCalendar'; // Import the custom calendar

function Step4(props) {
    console.log("step4","start");
  const {user} = useSelector((state) => state.auth)
  const department = user?.departments.find(d => d.id == props.formData.department_id)
 
            //const slots = props.formData.doctor.slots.sort((a, b) => dayjs(a.wdate).isBefore(dayjs(b.wdate)));
  const slots = props.formData.doctor.slots.sort((a, b) => {
  const dateA = dayjs(a.wdate);
  const dateB = dayjs(b.wdate);
  const timeA = dayjs(a.start_time, 'HH:mm:ss');
  const timeB = dayjs(b.start_time, 'HH:mm:ss');

  // Compare dates first
  if (!dateA.isSame(dateB)) {
    return dateA - dateB;
  }

  // Dates are the same, compare times
  return timeA - timeB;
});

  const firstSlot = slots[0];
  const initialValue = dayjs(firstSlot.wdate);
  
 const [highlightedDays, setHighlightedDays] = useState(
 slots.filter(s =>
 dayjs(s.wdate).isSame(dayjs(initialValue).startOf('month')) || 
(dayjs(s.wdate).isBefore(dayjs(initialValue).add(1, 'month').startOf('month')) &&  
dayjs(s.wdate).isAfter(dayjs(initialValue).startOf('month'))))
    .map(s => Number(dayjs(s.wdate).format('DD')))
  );
  

  const [currentDate, setCurrentDate] = useState(initialValue);
  
  const [times, setTimes] = useState(slots.filter(s => s.wdate == initialValue.format('YYYY-MM-DD')));
  const requestAbortController = useRef(null);

  


const ServerDay = (props) => {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const isSelected =
    !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;

  return (
      <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} disabled={!isSelected} day={day} />

  );
}

const Change = (value) => {
  setCurrentDate(value);
  
  let times = slots.filter(s => s.wdate == value.format('YYYY-MM-DD'));
  setTimes(times)
}

const ChooseTime = (time) => {
  
  props.setFormData({...props.formData, 
    slot_data_id: time.id,
    appointed_time: time.start_time,
    timeData: time,
  })
  props.next()
}

const handleMonthChange = (date) => {
  // Clear current times since we are moving to a new month.
  setTimes([]);

  // Calculate start and end of the new month.
  const monthStart = dayjs(date).startOf('month');
  const monthEnd = dayjs(date).endOf('month');

  // Filter slots in the new month.
  const slotsInMonth = slots.filter((s) => {
    const sDate = dayjs(s.wdate);
    return sDate.isBetween(monthStart, monthEnd, 'day', '[]');
  });

  // Update the highlighted days.
  setHighlightedDays(
    slotsInMonth.map(s => Number(dayjs(s.wdate).format('DD')))
  );

  // If there are available slots in the month, select the first available day.
  if (slotsInMonth.length > 0) {
    // Get all the day numbers in the month and choose the minimum.
    const firstAvailableDay = Math.min(
      ...slotsInMonth.map((s) => dayjs(s.wdate).date())
    );

    // Construct the new current date with the first available day.
    const newCurrentDate = dayjs(date).date(firstAvailableDay);

    // Update the parent state.
    setCurrentDate(newCurrentDate);

    // Also update the times for the selected day.
    const newTimes = slots.filter((s) =>
      dayjs(s.wdate).isSame(newCurrentDate, 'day')
    );
    setTimes(newTimes);
  } else {
    // If no slots, you might decide to update currentDate to the new month start
    // or keep it unchanged.
    setCurrentDate(date);
    setTimes([]);
  }
};

  
  return (
    <div className="step step4">   
      <h3>{department?.title}</h3>
      <h5>{props.formData.doctor.title}</h5>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <CustomCalendar
  views={["day"]}
  value={currentDate} 
  onChange={Change}
  onMonthChange={handleMonthChange}
  minDate={currentDate}
  slots={{ day: ServerDay }}
  slotProps={{ day: { highlightedDays } }}
/>

      </LocalizationProvider>

      <div className="time-warp">  
      {times.map(time => (
        <span key={time.id} className="time-slot" onClick={() => ChooseTime(time)}>{
          dayjs('2023-12-12 ' + time.start_time).format('HH:mm')
          }</span>
      ))}
      </div>
        
    </div>
  );
}

export default Step4;
