import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Form.css";
import { useLocation, useNavigate } from "react-router-dom";
import { IconButton } from '@mui/material';

import axios from "axios";
import { API_URL } from "../../redux/actions/api";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import Step5 from "./Steps/Step5";
import Step4 from "./Steps/Step4";
import Step6 from "./Steps/Step6";
import StepFailedAppoint from "./Steps/StepFailedAppoint";
import { sendAppointment, startForm } from "../../redux/actions/dataActions";

function Form() {
  const location = useLocation();
  const { success_visitor } = useSelector((state) => state.data)
  const { failed_visitor } = useSelector((state) => state.data)
    const { failed_visitor_error } = useSelector((state) => state.data)
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
    id_number: '',
    department_id: '',
    schedule_id: '',
    slot_data_id: '',
    appointed_time: ''
  })

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const BackFunc = () => {
     
       console.log("currentStep:"+currentStep); 
    if (currentStep == 1) {
      navigate('/');
      return;
    }
      if (currentStep < 1) {
      setCurrentStep(4);
      return;
    }
     if (currentStep == 5) {
      console.log("currentStep 5, back to 4");   
      setCurrentStep(4);
      return;
  }
    setCurrentStep(currentStep - 1);
  }

  useEffect(() => {
    dispatch(startForm());
  }, [])

  useEffect(() => {
    if (success_visitor && !failed_visitor) {
      setCurrentStep(6);
    }
  
  }, [success_visitor])
  
  useEffect(() => {
      
      if(failed_visitor) {
        
          setCurrentStep(0);
      }
  }, [failed_visitor])

  const Next = () => {
    if (currentStep == 5) {
      dispatch(sendAppointment(formData))
      return;
    }
    setCurrentStep(currentStep + 1)
  }
  
    const NextForFailed = () => {
          dispatch(startForm());
        setCurrentStep(4);
    }

let hostname = window.location.hostname;
        if (hostname.startsWith("www.")) {
         hostname = hostname.substring(4); // Remove "www."
         }
 const formMap = {
        "mayanot-hasharon.anstc.com": {form_margin_top:"0px" },
        "yamim.anstc.com": {form_margin_top:"90px" },
        "elad.anstc.com": {form_margin_top:"60px" },
         "mayanotata.anstc.com":{form_margin_top:"80px" },
        };
        
        const { form_margin_top} = formMap[hostname] || {form_margin_top:"0px" };

  const CurrentStepComponent = () => {
    switch (currentStep) {
      case 1:
        return <Step1 formData={formData} setFormData={setFormData} next={Next} />;
      case 2:
        return <Step2 formData={formData} setFormData={setFormData} next={Next} />
      case 3:
        return < Step3 formData={formData} setFormData={setFormData} next={Next} />
      case 4:
        return <Step4 formData={formData} setFormData={setFormData} next={Next} />
      case 5:
        return <Step5 formData={formData} setFormData={setFormData} next={Next} />
      case 6:
        return <Step6 formData={formData} setFormData={setFormData} next={Next} />
      case 0:
        return <StepFailedAppoint formData={formData} setFormData={setFormData} next={Next} next_for_failed={NextForFailed}  />  
    }
  }


  return (
    <div className="form_container" style={{marginTop:form_margin_top}}>
      <IconButton aria-label="back" onClick={BackFunc} className="back_btn" size="small" >
        <ArrowForwardIosIcon />
      </IconButton>
      <div className="stes-warp">
        <CurrentStepComponent />
      </div>



    </div>
  );
}

export default Form;
